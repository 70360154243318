

@keyframes fadeInUpCustom {
  0% {
    transform: translate(0px, 40px);
    opacity: 0;
  }
  100% {
    transform: translate(0px, 0px);
    opacity: 1;
  }
}

.animate__animated.animate__fadeInUp {
  animation-name: fadeInUpCustom;
}

